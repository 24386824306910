@import "~core/product/detail";

.product-detail-header {
    background-image: url('../images/pdp-header-background.jpg');
    background-repeat: no-repeat;
    background-position: center center;
    @include media-breakpoint-up(xl) {
        background-size: 100% auto;
    }

    .breadcrumb .breadcrumb-item a,
    .breadcrumb .breadcrumb-item + .breadcrumb-item:last-of-type a,
    .product-headline {
        color: $white;
    }

    .product-headline {
        @include header(4);
    }
}

.product-detail-secondary-header {
    background-color: $off-white;
    padding: $spacer-xl 0;
    @include media-breakpoint-up(lg) {
        padding: $spacer 0;
    }

    .product-duration {
        font-weight: 500;
        display: flex;
        align-items: center;
        @include icon($icon-time, before, 26px, $brand-primary);
        &::before {
            padding-right: $spacer;
        }

        @include media-breakpoint-up(lg) {
            margin-bottom: 0;
            padding-right: $spacer;
        }
    }

    .product-credits-icon {
        &::before {
            padding-right: $spacer;
            font-size: 32px;
            color: $brand-primary;
        }
    }

    .product-credits {
        font-weight: 500;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: 0;

        .product-credit:not(:last-child) {
            &::after {
                content: '|';
                color: $gray;
                margin: 0 $spacer-sm;
            }
        }
    }
}



.product-detail {
    .product-number-rating {
        margin-top: 0;
        @include media-breakpoint-up(lg) {
            padding-right: $spacer;
        }
        
        .product-number {
            display: none;
        }

        .ratings {
            margin-left: 0;
            color: $brand-primary;
            margin-bottom: $spacer;
            @include media-breakpoint-up(lg) {
                margin-bottom: 0;
            }

            .reviews-count {
                font-size: 16px;
                font-weight: 500;
                text-decoration: underline;
            }
        }
    }

    .prices .price {
        @include media-breakpoint-up(lg) {
            font-size: 24px;
        }
    }

    .product-availability {
        display: none;
    }

    .collapsible-content-group {
        .card-header {
            background-color: $off-white;
            border: 4px solid $light-gray;
        }
    }

    .non-color-attribute-swatches {
        .swatch-tile-container {
            .swatch {
                min-width: 145px;
                @include media-breakpoint-up(lg) {
                    min-width: 206px;
                }

                .description,
                .name {
                    font-weight: 500;
                    font-size: $non-color-swatch-description-font-size;
                    @include media-breakpoint-up(lg) {
                        font-size: 20px;
                    }
                }
            }
        }
    }
}

.product-detail-brochure-link {
    @include icon($icon-download, before, 14px, $brand-primary);
}

.product-detail-certificate-link {
    @include icon($icon-award, before, 14px, $brand-primary);
    @include media-breakpoint-up(md) {
        padding-left: $spacer-xl;
    }
}

.product-detail-brochure-link,
.product-detail-certificate-link {
    font-size: 14px;
    font-weight: 700;
    padding-top: $spacer;
    @include media-breakpoint-up(lg) {
        padding-top: $spacer-xl;
    }

    &::before {
        padding-right: $spacer-sm;
    }
}